<template>
    <v-row>
      <v-col class="px-0 background">
        <v-dialog v-model="dialog" width="870" persistent no-click-animation scrollable :fullscreen="fullscreen">
          <v-card class="pa-0">
            <v-toolbar color="transparent" flat height="46">
              <v-toolbar-title class="px-5 subtitle-2 fontBody--secondary font-weight-medium">Resumen del documento</v-toolbar-title>
              <v-spacer />
              <v-toolbar-items class="mr-4">
                <v-row align="center" justify="center" class="mr-n1">
                  <v-btn class="mr-2" @click="fullscreen=!fullscreen" icon small>
                    <v-tooltip color="bgSearch" left bottom transition="scale-transition" max-width="230" :open-delay="1000">
                      <template v-slot:activator="{on}">
                        <v-icon size="20" color="fontBody" v-on="on">{{ fullscreen ? 'mdi-fullscreen-exit' : 'mdi-fullscreen' }}</v-icon>
                      </template>
                     {{!fullscreen ? 'Pantalla completa' : 'Salir de pantalla completa'}}
                    </v-tooltip>
                  </v-btn>
                  <v-btn @click="$emit('close')" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
                </v-row>
              </v-toolbar-items>
            </v-toolbar>
            <v-card-text class="pa-0">
              <v-divider  />
              <!-- documents inf -->
              <template v-if="!item.data.shipping">
                <v-row no-gutters>
                  <v-col class="px-5 py-4">
                    <span class="subtitle-2 secondary--text font-weight-bold">
                      <v-icon color="secondary" left size="22">mdi-bank</v-icon>
                      Información del receptor
                    </span>
                    <v-sheet class="mt-3 transparent">
                      <v-list class="v-list-form-extended-medium transparent pa-0">
                        <v-list-item class="pa-0">
                          <v-list-item-title class="fontDraw--text subtitle-2">Empresa</v-list-item-title>
                          <v-list-item-subtitle class="body-1 fontBody--text">{{(((item || {}).data || {}).customer || {}).name || (((item || {}).data || {}).taxpayer || {}).name}}</v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item class="pa-0">
                          <v-list-item-title class="fontDraw--text subtitle-2">{{$t('generals.RUT')}}</v-list-item-title>
                          <v-list-item-subtitle class="body-1 fontBody--text">20516741628</v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item class="pa-0">
                          <v-list-item-title class="fontDraw--text subtitle-2">Comuna</v-list-item-title>
                          <v-list-item-subtitle class="body-1 fontBody--text">ASUNCION</v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item class="pa-0">
                          <v-list-item-title class="fontDraw--text subtitle-2">Dirección</v-list-item-title>
                          <v-list-item-subtitle class="body-1 fontBody--text">
                            <template v-if="(((item || {}).data || {}).customer || {}).address || (((item || {}).data || {}).taxpayer || {}).address">El pelícano #123</template>
                            <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                          </v-list-item-subtitle>
                        </v-list-item>
                      </v-list>
                    </v-sheet>
                  </v-col>
                  <v-divider vertical />
                  <v-col class="px-5 py-4">
                    <span class="subtitle-2 secondary--text font-weight-bold">
                      <v-icon color="secondary" left size="22">mdi-file-document</v-icon>
                      Información del documento
                    </span>
                    <v-sheet class="mx-0 mt-3 transparent">
                      <v-list class="v-list-form-extended-medium transparent pa-0">
                        <v-list-item class="pa-0">
                          <v-list-item-title class="fontDraw--text subtitle-2">Tipo de doc.</v-list-item-title>
                          <v-list-item-subtitle class="body-1 fontBody--text">{{(((item || {}).data || {}).document_type || '') | nameDocumentsType}}</v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item class="pa-0" v-if="item.data.status !== 'draft'">
                          <v-list-item-title class="fontDraw--text subtitle-2">{{$t('generals.Folio')}}</v-list-item-title>
                          <v-list-item-subtitle class="body-1 fontBody--text">T011 - 878</v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item class="pa-0">
                          <v-list-item-title class="fontDraw--text subtitle-2">ID</v-list-item-title>
                          <v-list-item-subtitle class="body-1 fontBody--text">
                            <v-tooltip color="bgSearch" right transition="scale-transition" max-width="320">
                              <template v-slot:activator="{on}">
                                <v-btn @click="toClipboard(item.id)" text v-on="on" :ripple="false">{{item.id}}</v-btn>
                              </template>
                              <span class="d-block px-3 py-2">Copiar</span>
                            </v-tooltip>
                          </v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item class="pa-0">
                          <v-list-item-title class="fontDraw--text subtitle-2">Creado el</v-list-item-title>
                          <v-list-item-subtitle class="body-1 fontBody--text">{{item.data.created | dateTime}}</v-list-item-subtitle>
                        </v-list-item>
                      </v-list>
                    </v-sheet>
                  </v-col>
                </v-row>
                <v-divider />
              </template>
              <!-- document inf -->

              <!-- carrier inf -->
              <template v-else>
                <v-row no-gutters>
                  <v-col class="px-5 py-4">
                    <span class="subtitle-2 secondary--text font-weight-bold">
                      <v-icon color="secondary" left size="22">mdi-file-document</v-icon>
                      Información del documento
                    </span>
                    <v-sheet class="mx-0 mt-3 transparent">
                      <v-list class="v-list-form-extended-medium transparent pa-0">
                        <v-list-item class="pa-0">
                          <v-list-item-title class="fontDraw--text subtitle-2">Tipo de doc.</v-list-item-title>
                          <v-list-item-subtitle class="body-1 fontBody--text">{{(((item || {}).data || {}).document_type || '') | nameDocumentsType}}</v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item class="pa-0" v-if="item.data.status !== 'draft'">
                          <v-list-item-title class="fontDraw--text subtitle-2">{{$t('generals.Folio')}}</v-list-item-title>
                          <v-list-item-subtitle class="body-1 fontBody--text">T011 - 878</v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item class="pa-0">
                          <v-list-item-title class="fontDraw--text subtitle-2">Empresa</v-list-item-title>
                          <v-list-item-subtitle class="body-1 fontBody--text" v-if="(((item || {}).data || {}).customer || {}).name">{{(((item || {}).data || {}).customer || {}).name || ''}}</v-list-item-subtitle>
                          <v-list-item-subtitle  class="body-2 text--disabled font-italic" v-else>Sin especificar</v-list-item-subtitle >
                        </v-list-item>
                        <v-list-item class="pa-0">
                          <v-list-item-title class="fontDraw--text subtitle-2">{{$t('generals.RUT')}}</v-list-item-title>
                          <v-list-item-subtitle class="body-1 fontBody--text">{{(((item || {}).data || {}).customer || {}).taxpayer || ''}}</v-list-item-subtitle>
                        </v-list-item>
                      </v-list>
                    </v-sheet>
                  </v-col>
                  <v-col cols="6" class="py-6 mt-7 pl-8 d-flex">
                    <v-list class="v-list-form-extended-medium transparent pa-0 mt-n1" width="100%">
                      <v-list-item class="pa-0">
                        <v-list-item-title class="fontDraw--text subtitle-2">Creado el</v-list-item-title>
                        <v-list-item-subtitle class="body-1 fontBody--text">{{item.data.created | dateTime}}</v-list-item-subtitle>
                      </v-list-item>
                      <v-list-item class="pa-0">
                        <v-list-item-title class="fontDraw--text subtitle-2">Tipo de envio</v-list-item-title>
                        <v-list-item-subtitle class="body-1 fontBody--text" v-if="shippingType(item.data.shipping.type)">{{ shippingType(item.data.shipping.type) }}</v-list-item-subtitle>
                        <v-list-item-subtitle  class="body-2 text--disabled font-italic" v-else>Sin especificar</v-list-item-subtitle >
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>
                  <v-divider />
                <v-row class="ma-0" no-gutters>
                  <v-col cols="6" class="px-5 py-4">
                    <span class="subtitle-2 secondary--text font-weight-bold">
                      <v-icon color="secondary" left size="22">mdi-map-marker</v-icon>
                      Información del receptor
                    </span>
                    <v-sheet class="mx-0 mt-3 transparent">
                      <v-list class="v-list-form-extended-medium transparent pa-0">
                        <v-list-item class="pa-0">
                          <v-list-item-title class="fontDraw--text subtitle-2">Nombre y Apellido</v-list-item-title>
                          <v-list-item-subtitle class="body-1 fontBody--text">{{(((item || {}).data || {}).customer || {}).name || ''}}</v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item class="pa-0">
                          <v-list-item-title class="fontDraw--text subtitle-2">{{$t('generals.RUT')}}</v-list-item-title>
                          <v-list-item-subtitle class="body-1 fontBody--text">{{(((item || {}).data || {}).customer || {}).taxpayer || ''}}</v-list-item-subtitle>
                        </v-list-item>
                        <v-list-item class="pa-0">
                          <v-list-item-title class="fontDraw--text subtitle-2">Comuna</v-list-item-title>
                          <v-list-item-subtitle class="body-1 fontBody--text text-capitalize" v-if="(((item || {}).data || {}).customer || {}).state">{{(((item || {}).data || {}).customer || {}).state || '' | lower}}</v-list-item-subtitle>
                          <v-list-item-subtitle class="body-2 text--disabled font-italic" v-else>Sin especificar</v-list-item-subtitle >
                        </v-list-item>
                        <v-list-item class="pa-0">
                          <v-list-item-title class="fontDraw--text subtitle-2">Dirección</v-list-item-title>
                          <v-list-item-subtitle class="body-1 fontBody--text">
                            <template v-if="(((item || {}).data || {}).customer || {}).address">{{(((item || {}).data || {}).customer || {}).address}}</template>
                            <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                          </v-list-item-subtitle>
                        </v-list-item>
                      </v-list>
                    </v-sheet>
                  </v-col>
                  <v-divider vertical />

                  <v-col class="px-5 py-4">
                    <span class="subtitle-2 secondary--text font-weight-bold">
                      <v-icon color="secondary" left size="22">mdi-truck</v-icon>
                      Datos del transporte
                    </span>
                    <v-sheet class="mx-0 mt-3 transparent">
                      <v-list class="v-list-form-extended-medium transparent pa-0">
                        <v-list-item class="pa-0">
                          <v-list-item-title class="fontDraw--text subtitle-2">Nombre y Apellido</v-list-item-title>
                          <v-list-item-subtitle class="body-1 fontBody--text" v-if="(((item || {}).data || {}).shipping || {}).carrier_name">{{(((item || {}).data || {}).shipping || {}).carrier_name || ''}}</v-list-item-subtitle>
                          <v-list-item-subtitle  class="body-2 text--disabled font-italic" v-else>Sin especificar</v-list-item-subtitle >
                        </v-list-item>
                        <v-list-item class="pa-0">
                          <v-list-item-title class="fontDraw--text subtitle-2">{{$t('generals.RUT')}}</v-list-item-title>
                          <v-list-item-subtitle class="body-1 fontBody--text" v-if="(((item || {}).data || {}).shipping || {}).carrier_id">{{(((item || {}).data || {}).shipping || {}).carrier_id || ''}}</v-list-item-subtitle>
                          <v-list-item-subtitle  class="body-2 text--disabled font-italic" v-else>Sin especificar</v-list-item-subtitle >
                        </v-list-item>
                        <v-list-item class="pa-0">
                          <v-list-item-title class="fontDraw--text subtitle-2">Patente</v-list-item-title>
                          <v-list-item-subtitle class="body-1 fontBody--text" v-if="(((item || {}).data || {}).shipping || {}).licence_plate">{{(((item || {}).data || {}).shipping || {}).licence_plate || ''}}</v-list-item-subtitle>
                          <v-list-item-subtitle  class="body-2 text--disabled font-italic" v-else>Sin especificar</v-list-item-subtitle >
                        </v-list-item>
                      </v-list>
                    </v-sheet>
                  </v-col>
                </v-row>
                <v-divider  />
              </template>
              <!-- end carrier inf -->

              <!-- products -->
              <v-col class="px-0 py-4">
                <span class="px-5 subtitle-2 secondary--text font-weight-bold">
                  <v-icon color="secondary" left size="22">mdi-basket</v-icon>
                  Productos
                </span>
                <v-simple-table class="table-detail pa-0 mt-2 pr-2" dense :height="!fullscreen ? 300 : 'auto'">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left" style="min-width: 80px;">Productos</th>
                        <th class="text-left" style="min-width: 180px;">Descripción</th>
                        <th class="text-right">Valor</th>
                        <th class="text-right">Descuentos</th>
                        <th class="text-right" style="min-width: 80px;">Cant.</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(elm, index) in item.data.items" :key="index">
                        <td>
                          <span class="d-inline-block text-truncate" style="max-width: 180px;">{{elm.name}}</span>
                        </td>
                        <td class="text-left" style="min-width: 180px;">
                          <span class="d-inline-block text-truncate" style="max-width: 240px;" v-if="elm.description">{{elm.description}}</span>
                          <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                        </td>
                        <td class="text-right">{{elm.amount | currency(item.data.document_type)}}
                          <span class="fontDraw--text caption ml-1">PE</span>
                        </td>
                        <td class="text-right">
                          <template v-if="(elm.data || {}).discount">{{elm.discount}}</template>
                          <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                        </td>
                        <td class="text-right">{{elm.quantity}}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
              <!-- end products -->
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </template>
  <script>
  import GenericViewMixin from '@/mixins/GenericViewMixin'
  import ListViewMixin from '@/mixins/ListViewMixin'
  import VEmptyState from '@/components/commons/VEmptyState'

  export default {
    props: {
      item: {
        type: Object,
        default: () => ({})
      }
    },
    components: {
      VEmptyState
    },
    mixins: [
      ListViewMixin,
      GenericViewMixin
    ],
    data: () => ({
      fullscreen: false,
      dialog: true,
      shippingTypesList: [
        {
          id: '1',
          name: 'Operacion constituye venta'
        },
        {
          id: '2',
          name: 'Ventas por efectuar'
        },
        {
          id: '3',
          name: 'Consignaciones'
        },
        {
          id: '4',
          name: 'Entrega gratuita'
        },
        {
          id: '5',
          name: 'Traslados internos'
        },
        {
          id: '6',
          name: 'Otros traslados no venta'
        },
        {
          id: '7',
          name: 'Guia de devolución'
        },
        {
          id: '8',
          name: 'Traslado para exportación. (no venta)'
        },
        {
          id: '9',
          name: 'Venta para exportación'
        },
        {
          id: '01',
          name: 'Venta'
        },
        {
          id: '02',
          name: 'Compra'
        },
        {
          id: '03',
          name: 'Venta con entrega a terceros'
        },
        {
          id: '04',
          name: 'Traslado entre establecimientos de la misma empresa'
        },
        {
          id: '05',
          name: 'Consignación'
        },
        {
          id: '06',
          name: 'Devolución'
        },
        {
          id: '07',
          name: 'Recojo de bienes transformados'
        },
        {
          id: '08',
          name: 'Importación'
        },
        {
          id: '09',
          name: 'Exportación'
        },
        {
          id: '13',
          name: 'Otros'
        },
        {
          id: '14',
          name: 'Venta sujeta a confirmación del comprador'
        },
        {
          id: '17',
          name: 'Traslado de bienes para transformación'
        },
        {
          id: '18',
          name: 'Traslado emisor itinerante CP'
        }
      ]
    }),
    computed: {
      number () {
        return this.$store.state?.auth?.account?.country === 'PE' ? `${this.item.data.prefix} - ${this.item.data.number}` : this.item.data.number
      },
      shippingType () {
        return (id) => {
          return this.shippingTypesList.find((item) => item.id === id?.toString())?.name
        }
      }
    },
    created () {
    },
    methods: {
     // df
    }
  }
  </script>